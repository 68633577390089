const imgCacheKey = 'img-cache-key'

export const set = (src: string): void => {
  try {
    const data = JSON.parse(sessionStorage.getItem(imgCacheKey) || '{}')
    data[src] = new Date()
    sessionStorage.setItem(imgCacheKey, JSON.stringify(data))
  } catch (error) {
    console.error(`img: ${error}`)
  }
}

export const get = (src: string): boolean => {
  try {
    const data = JSON.parse(sessionStorage.getItem(imgCacheKey) || '{}')
    return !!data[src]
  } catch (error) {
    console.error(`img: ${error}`)
    return false
  }
}
