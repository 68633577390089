import { JsonRpcSigner } from '@ethersproject/providers'
// import { parseUnits } from 'ethers/lib/utils'
import { formatUnits, parseUnits } from '@ethersproject/units'
import { useEffect } from 'react'
import styled from 'styled-components'
import { useClient, useProvider, useSigner } from 'wagmi'
import { PrimaryButton } from '../components/core/Buttons'
import { Spacer } from '../components/core/Spacer'
import { H4Responsive } from '../components/core/Typography'
import { GobblersImg } from '../components/image/Image'
import { FaqStories } from '../components/stories/stories'
import { getAddressesForGobblerInstance, DEFAULT_CHAIN_ID } from '../config'
import { ArtGobblers__factory, Goo__factory, Pages__factory } from '../contracts'
import { useStaticRpcProvider } from '../hooks/eth/useStaticRpcProvider'
import { trpc } from '~/utils/trpc'
import { useContractAddresses } from 'hooks/gobblers/useContractAddresses'

const FullscreenContainer = styled.div<{ blur: boolean }>`
  position: relative;
  min-height: 100vh;
  width: 100%;
  min-width: 100vw;
  max-width: 100vw;
  max-height: 100vh;
  overflow-x: hidden;
  overflow-y: hidden;
  /* overflow: hidden; */
  /* to blur bg */
  transition: 350ms filter cubic-bezier(0.39, 0.575, 0.565, 1),
    350ms opacity cubic-bezier(0.39, 0.575, 0.565, 1);
  ${(props) =>
    props.blur &&
    `
    position: relative;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    background: inherit;
    box-shadow: inset 0 0 0 200px rgba(255,255,255,0.2);
    filter: blur(4px);
    opacity: 0.5;
    content: "";
  `}
`

const BackgroundImageContainer = styled.div`
  height: 100vh;
  position: absolute;
  width: 100vw;
  max-width: 100vw;
  max-height: 100vh;
  overflow: hidden;
  top: 0;
  z-index: 0;
`

const BackgroundImage = styled.img`
  /* max-height: 100vh; */
  top: 0;
  position: relative;
  /* height: 100%; */
  min-width: 100vw;
  object-fit: cover;
  min-height: 100vh;
  max-width: 100vw;
`

const CenteredContent = styled.div`
  display: flex;
  justify-content: center;
  padding-top: 210px;
  z-index: 1;
  position: relative;
`

const ModalThing = styled.div`
  position: relative;
  background: #26262b;
  display: flex;
  flex-direction: column;
  align-items: center;
  z-index: 3;
  /* Middle Shadow */

  box-shadow: -1px -3px 4px rgba(255, 255, 255, 0.2), 0px 16px 32px rgba(27, 26, 37, 0.24),
    0px 12px 24px rgba(27, 26, 37, 0.16), 0px 4px 8px rgba(27, 26, 37, 0.12);
  border-radius: 28px;
  padding: 64px 88px 64px 88px;

  min-width: 785px;
`

const H1 = styled.h1`
  font-size: 48px;
  color: #ffffff;
`

export default function Home() {
  const { data: signer } = useSigner()
  const uncheckedSigner = (signer as JsonRpcSigner | undefined)?.connectUnchecked()

  const chainID = DEFAULT_CHAIN_ID
  const provider = useStaticRpcProvider(chainID)

  const addresses = useContractAddresses(chainID)

  useEffect(() => {
    const doAsync = async () => {
      if (!provider) {
        return
      }
      if (!addresses) {
        return
      }

      const artGobblers = ArtGobblers__factory.connect(addresses.ART_GOBBLERS, provider)

      const pages = Pages__factory.connect(addresses.PAGES, provider)
      const goop = Goo__factory.connect(addresses.GOO, provider)

      const goopPrice = await artGobblers.gobblerPrice()

      const units = formatUnits(goopPrice.toString(), 18)
      console.log('goop price', goopPrice.toString(), 'units', units.toString())
      // // console.log('goop price', goopPrice.div(10000000000000000).toString())

      // const mintTx = await artGobblers.mintFromGoop()
      // console.log('txHash:eager', mintTx.hash)
      // const txReceipt = await mintTx.wait()
      // console.log('full tx', txReceipt)
      // console.log('txHash:finalized', txReceipt.transactionHash)
    }

    // 0xacbf2e56700af1f39ac9db6ca69b8087c457728e77b3d11ad35ec766a0536eba

    doAsync()
  }, [addresses, provider])

  return (
    <>
      <link rel={'preload'} href={'/images/three-gobbler-avatar-banner.png'} as={'image'} />
      <link rel={'preload'} href={'/images/home-bg.png'} as={'image'} />
      <FullscreenContainer blur={false}>
        <BackgroundImageContainer>
          <BackgroundImage width={3024} height={1922} src={'/images/home-bg-2.png'} />
        </BackgroundImageContainer>
        <BottomSlimeContainer>
          <GobblersImg width={3350} height={490} src={'/images/slime-bg-static.png'} />
        </BottomSlimeContainer>
        <CenteredContent>
          <span>gobblegobble</span>

          {false && (
            <ModalThing>
              <GobblerAboveModalContainer>
                <GobblersImg
                  width={296}
                  height={135}
                  src={'/images/three-gobbler-avatar-banner.png'}
                />
              </GobblerAboveModalContainer>
              {/* <H1>Gobbler homepage pane</H1> */}
              {/* <H4Responsive style={{ fontWeight: 400, opacity: 0.5}}>
              Introducing
            </H4Responsive> */}
              <Spacer size={24} />

              <GobblersImg src={'/images/main-logo.png'} />
              <Spacer size={32} />

              {/* <H4Responsive style={{ fontWeight: 400, textAlign: 'center' }}>
              <span style={{ fontWeight: 700 }}>10,000</span> hungry aliens here to cover the world
              in
              <br /> <span style={{ color: '#8EF42E', fontWeight: 700 }}>$GOO</span> and eat the
              coolest art they can find.
            </H4Responsive> */}
              <Spacer size={32} />
              {/* <PrimaryButton style={{ minHeight: 86, maxWidth: 392 }}>Learn more</PrimaryButton> */}
              {/* <FaqStories /> */}
            </ModalThing>
          )}
        </CenteredContent>
        {/* <GooHoseContainer>
          <GobblersImg width={286} height={478} src={'/images/goop-hose.png'} />
        </GooHoseContainer>

        <GobContainer>
          <GobblersImg width={1000} src={'/images/gob-a1.gif'} />
        </GobContainer> */}

        {/* <CreditsContainerRow>
          <div>
            <H4Responsive style={{ fontWeight: 400 }}>
              By <span style={{ fontWeight: 700 }}>Justin Roland</span> and{' '}
              <span style={{ fontWeight: 700 }}>Paradigm</span>
            </H4Responsive>
          </div>
        </CreditsContainerRow> */}

        {/* <StoriesBackground>
          <FaqStories />
        </StoriesBackground> */}
      </FullscreenContainer>
    </>
  )
}

const BottomSlimeContainer = styled.div`
  position: absolute;
  bottom: -64px;
  left: -36px;
  right: -36px;
  z-index: 1;
`

const CreditsContainerRow = styled.div`
  position: absolute;
  bottom: 52px;
  left: 72px;
  right: 0px;
  display: flex;
  align-items: center;
  z-index: 2;
`

const GobblerAboveModalContainer = styled.div`
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  left: 0;
  right: 0;
  top: -70px;
  z-index: 4;
  /* transform: translateY(-50%); */
`

const GobContainer = styled.div`
  position: absolute;
  right: -300px;
  bottom: 30px;
  z-index: 3;
`

const GooHoseContainer = styled.div`
  position: absolute;
  left: 0px;
  bottom: 40px;
  z-index: 3;
`

const StoriesBackground = styled.div`
  position: absolute;
  left: 0px;
  right: 0px;
  bottom: 0px;
  top: 0px;
  z-index: 11;
  background: #26262b;
  padding: 32px 32px;
`
