import { useEffect, useState } from 'react'
import { StaticJsonRpcProvider } from '@ethersproject/providers'
import { getJsonRpcProviderForChain } from '../../web3/chains'

const useStaticRpcProvider = (chainId: string | number | undefined) => {
  // TODO(johnrjj) - Use global cache/context instead of local state
  const [rpc, setRpc] = useState<StaticJsonRpcProvider | undefined | null>(undefined)

  useEffect(() => {
    if (chainId === undefined) {
      setRpc(undefined)
    } else {
      const _rpc = getJsonRpcProviderForChain(chainId)
      if (!_rpc) {
        setRpc(null)
      } else {
        setRpc(_rpc)
      }
    }
  }, [chainId])

  return rpc
}

export { useStaticRpcProvider }
